import { Button, Col, Row } from "react-bootstrap";
import { useOutletContext } from "react-router";
import { t } from "../data/localization";
import { formatDate, formatTime } from "../data/utils";
import { Parser } from "html-to-react";

const FrontendServiceStepConfirmationSession = ({
    confirmationPage,
    confirmationCode,
    location,
    groupSessionDates,
    appointment
}) => {
    const locale = useOutletContext();
    const handlePrintButtonClick = () => {
        window.print();
    };

    let sessionDatesHtml = '<ul>';
    groupSessionDates.forEach(date => sessionDatesHtml += '<li>' + formatDate(date.date) + ' ' + t('at') + ' ' + formatTime(date.time) + '</li>');
    sessionDatesHtml += '</ul>';

    const template = confirmationPage
        .replace('[CODE]', confirmationCode.toUpperCase())
        .replace('[GROUP_SESSION_DATES]', sessionDatesHtml)
        .replace(
            '[LOCATION]',
            location.address + ', ' +
            location.city + ', ' +
            location.postalCode
        )
        .replace('[CLIENTS]', appointment.clientData.map(_ => _.preferredName ? _.firstName + ' ' + _.lastName + ' (' + _.preferredName + ')' : _.firstName + ' ' + _.lastName).join(', '));

    return (
        <Row>
            <Col>
                <img className="d-block my-2 img-fluid mx-auto d-print-none" src="/success-checkmark.png" title="success" height="25%" />
                {Parser().parse(template)}
                <Button variant="primary" className="w-100 d-print-none" size="lg" onClick={handlePrintButtonClick}>{t('Print', locale)}</Button>
            </Col>
        </Row>
    );
};

export default FrontendServiceStepConfirmationSession;
