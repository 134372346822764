import { Parser } from 'html-to-react';
import { sendRequest } from './api';
import { convertSecondsToTime, convertTimeToSeconds, PHONE_REGEX } from './utils';
import { t } from './localization';

export const createLock = (time, calendarDateLaneId, duration) => {
    const endTimeSeconds = convertTimeToSeconds(time) + parseInt(duration) - 1;
    const endTime = convertSecondsToTime(endTimeSeconds);
    const data = {
        calendarDateLaneId: calendarDateLaneId,
        startTime: time,
        endTime: endTime
    };
    return sendRequest('public/locks', 'POST', data);
};

export const createLocks = (timeslots) => {
    const data = {
        items: timeslots.map(timeslot => {
            const endTimeSeconds = convertTimeToSeconds(timeslot.time) + parseInt(timeslot.duration) - 1;
            const endTime = convertSecondsToTime(endTimeSeconds);
            return {
                calendarDateLaneId: timeslot.id,
                startTime: timeslot.time,
                endTime: endTime
            };
        })
    };

    return sendRequest('public/locks/batch', 'POST', data);
}

export const deleteLocks = (tokens) => {
    const data = {
        items: tokens
    };
    return sendRequest('public/locks/batch', 'DELETE', data);
};

export const deleteLock = (token) => {
    return sendRequest('public/locks/' + token, 'DELETE');
};

export const getCalendarDates = (id, calendarId, filters) => {
    const urlParams = new URLSearchParams(filters).toString();
    return sendRequest('public/services/' + id + '/calendar/' + calendarId + '/dates?' + urlParams);
};

export const getGroupSessions = (id, calendarId, filters) => {
    const urlParams = new URLSearchParams(filters).toString();
    return sendRequest('public/services/' + id + '/calendar/' + calendarId + '/sessions?' + urlParams);
};

export const getService = (id, locale = 'en') => {
    return sendRequest('public/services/' + id + '?locale=' + locale);
}

export const getServices = (locale = 'en') => {
    const params = {
        locale: locale
    };
    const serializedParams = new URLSearchParams(params).toString();
    return sendRequest('public/services?' + serializedParams);
};

export const createAppointment = (appointment) => {
    return sendRequest('public/appointments', 'POST', appointment);
};

export const getClientFields = (filteredFields = [], locale = 'en') => {
    const currentDate = new Date();
    let clientFields = [
        {
            name: 'firstName',
            label: t('First name:', locale),
            placeholder: t('Enter first name', locale),
            type: 'text',
            required: true
        },
        {
            name: 'lastName',
            label: t('Last name:', locale),
            placeholder: t('Enter last name', locale),
            type: 'text',
            required: true
        },
        {
            name: 'clientId',
            label: 'Client ID',
            type: 'text',
            pattern: '[0-9]{10}',
            maxLength: 10,
            required: false
        },
        {
            name: 'preferredName',
            label: t('Preferred name (if different from what appears on health card):', locale),
            placeholder: t('Enter preferred name', locale),
            type: 'text',
            required: false
        },
        {
            name: 'pronoun',
            label: t('Preferred pronoun:', locale),
            placeholder: t('Select preferred pronoun', locale),
            type: 'select',
            required: false,
            showListing: true,
            showForm: true,
            options: [
                {
                    label: t('He', locale),
                    value: 'he'
                },
                {
                    label: t('She', locale),
                    value: 'she'
                },
                {
                    label: t('They', locale),
                    value: 'they'
                }
            ]
        },
        {
            name: 'dateOfBirth',
            label: t('Date of birth:', locale),
            placeholder: t('Enter date of birth', locale),
            type: 'date',
            showAge: true,
            maxDate: new Date(currentDate.getFullYear(), 12, 31),
            required: false
        },
        {
            name: 'legalGuardian',
            label: t('Legal guardian/substitute decision maker first and last name (if applicable):', locale),
            placeholder: t('Enter first and last name', locale),
            type: 'text',
            required: false
        },
        {
            name: 'notGuardian',
            label: t('Individual accompanying client is NOT the parent or legal guardian:', locale),
            type: 'checkbox',
            required: false
        },
        {
            name: 'authorized',
            label: t('Authorized by the program manager:', locale),
            type: 'checkbox',
            required: false
        },
        {
            name: 'translationServices',
            label: t('Translation Services Required:', locale),
            type: 'checkbox',
            required: false
        }
    ];

    if (filteredFields.length > 0) {
        clientFields = clientFields
            .filter(current => filteredFields.find(item => item.name === current.name) || current.required)
            .map(current => current.required ? current : { ...current, required: filteredFields.find(item => item.name === current.name).required });
    } else {
        clientFields = clientFields.filter(x => x.required);
    }

    return clientFields;
};

export const getAppointmentFields = (
    service,
    calendarFields,
    appointmentState,
    emailsEnabled = false,
    locale = 'en'
) => {
    const clientFields = getClientFields(calendarFields, locale);
    let clientDataState = {};
    const PHONE_REMINDER_NONE = 0;
    const PHONE_REMINDER_PRIMARY_SMS = 2;
    const PHONE_REMINDER_SECONDARY_SMS = 4;
    const PHONE_REMINDER_CUSTOM_SMS = 6;
    const phoneReminderOptions = [
        {
            label: t('No reminder', locale),
            value: PHONE_REMINDER_NONE
        },
        {
            label: t('SMS - Primary Phone Number', locale),
            value: PHONE_REMINDER_PRIMARY_SMS
        },
        {
            label: t('SMS - Secondary Phone Number', locale),
            value: PHONE_REMINDER_SECONDARY_SMS
        },
        {
            label: t('SMS - Alternate Phone Number', locale),
            value: PHONE_REMINDER_CUSTOM_SMS
        }
    ];
    clientFields.forEach(current => clientDataState[current.name] = null);

    if (clientFields.find(x => x.name === 'dateOfBirth')) {
        clientFields.find(x => x.name === 'dateOfBirth').showAge = false;
    }

    let fields = [
        {
            name: 'clientData',
            label: '',
            singularName: t('Client Information'),
            type: 'repeater',
            required: true,
            min: appointmentState.clientData.length,
            max: appointmentState.clientData.length,
            defaultState: {},
            fields: clientFields
        },
        {
            name: 'language',
            label: t('Preferred language:', locale),
            placeholder: t('Select preferred language', locale),
            type: 'select',
            required: true,
            options: [
                {
                    label: t('English', locale),
                    value: 'en'
                },
                {
                    label: t('French', locale),
                    value: 'fr'
                }
            ]
        },
        {
            name: 'phone',
            label: t('Primary phone number:', locale),
            placeholder: t('Enter primary phone number', locale),
            pattern: PHONE_REGEX,
            type: 'tel',
            required: true
        },
        {
            name: 'phoneDoNotCall',
            label: t('Do Not Call Primary Number:', locale),
            type: 'checkbox',
            required: false
        },
        {
            name: 'phoneAlt',
            label: t('Secondary phone number:', locale),
            placeholder: t('Enter secondary phone number', locale),
            pattern: PHONE_REGEX,
            type: 'tel',
            required: false
        },
        {
            name: 'phoneAltDoNotCall',
            label: t('Do Not Call Secondary Number:', locale),
            type: 'checkbox',
            required: false
        },
        {
            name: 'phoneReminderType',
            label: t('Voice/SMS Reminder Type:', locale),
            type: 'select',
            placeholder: t('Select a value', locale),
            required: true,
            options: phoneReminderOptions
        },
        {
            type: 'custom',
            element: <div>{Parser().parse(service.emailContent)}</div>
        },
        {
            name: 'email',
            label: t('Email:', locale),
            placeholder: t('Enter a valid email', locale),
            type: 'email',
            required: service.emailRequired
        }
    ];

    /* Remove preferred language field */
    if (service.removePreferredLanguage) {
        fields = fields.filter(x => x.name !== 'language');
    }

    /* Remove Phone Alt field */
    if (service.removePhoneAlt) {
        fields = fields.filter(x => x.name !== 'phoneAlt');
    }

    /* Remove Phone Do Not Call field */
    if (service.removePhoneDoNotCall) {
        fields = fields.filter(x => x.name !== 'phoneDoNotCall');
    }

    /* Remove Phone Alt Do Not Call field */
    if (service.removePhoneAltDoNotCall) {
        fields = fields.filter(x => x.name !== 'phoneAltDoNotCall');
    }

    /* Remove Reminder Type field */
    if (service.removePhoneReminderType) {
        fields = fields.filter(x => x.name !== 'phoneReminderType');
    }

    /* Emails are enabled */
    if (emailsEnabled && appointmentState.email !== null) {
        fields = fields.concat({
            name: 'sendConfirmationEmail',
            label: t('Send confirmation email.', locale),
            type: 'checkbox'
        },
        {
            name: 'sendReminderEmail',
            label: t('Send reminder email.', locale),
            type: 'checkbox'
        });
    }

    /* Consent required checked */
    if (service.consentRequired) {
        fields = fields.concat(
            [
                {
                    name: 'agreeValidInfo',
                    type: 'checkbox',
                    label: t('I attest that, to the best of my knowledge and belief, all information provided is accurate. If the information provided cannot be verified or is inaccurate, this may result in cancellation of the appointment or registration.*', locale),
                    required: true
                },
                {
                    name: 'agreePhone',
                    type: 'checkbox',
                    label: t('I consent to receive communications by phone.*', locale),
                    required: true
                }
            ]
        );
    }

    /* Add in custom phone number for the reminder */
    if (
        parseInt(appointmentState.phoneReminderType) === PHONE_REMINDER_CUSTOM_SMS
    ) {
        const phoneReminderCustom = {
            name: 'phoneReminderCustom',
            label: t('Voice/SMS Reminder Phone Number:'),
            placeholder: t('Enter primary phone number (10 digits)'),
            maxLength: 12,
            pattern: PHONE_REGEX,
            type: 'tel',
            required: true
        };
        const phoneReminderIndex = fields.findIndex(x => x.name === 'phoneReminderType') + 1;
        fields.splice(
            phoneReminderIndex,
            0,
            phoneReminderCustom
        );
    }

    /* Hide primary phone number options if do not call is checked */
    if (appointmentState.phoneDoNotCall) {
        fields = fields.map(x => x.name === 'phoneReminderType' ? {
            ...x,
            options: phoneReminderOptions.filter(
                y => y.value !== PHONE_REMINDER_PRIMARY_SMS
            )
        } : x);
    }

    /* Hide secondary phone number options if do not call is checked */
    if (appointmentState.phoneAltDoNotCall) {
        fields = fields.map(x => x.name === 'phoneReminderType' ? {
            ...x, options: phoneReminderOptions.filter(
                y => y.value !== PHONE_REMINDER_SECONDARY_SMS)
        } : x);
    }

    return fields;
};

export const getDefaultAppointmentState = (
    calendarDateLaneId,
    time,
    duration,
    clientCount = 1,
    spots = 1,
    lockToken = null,
    locale = 'en'
) => {
    const startTimeInSeconds = convertTimeToSeconds(time);
    const endTimeInSeconds = startTimeInSeconds + parseInt(duration);
    let clients = [];
    for (let i = 0; i < clientCount; i++) {
        clients.push({});
    }

    const state = {
        calendarDateLaneId: calendarDateLaneId,
        startTime: startTimeInSeconds,
        duration: duration,
        endTime: endTimeInSeconds,
        email: null,
        multipleClients: clientCount > 1,
        clientData: clients,
        language: locale,
        phone: null,
        phoneDoNotCall: false,
        phoneAlt: null,
        phoneAltDoNotCall: false,
        phoneReminderType: 0,
        phoneReminderCustom: null,
        agreeValidInfo: false,
        agreePhone: false,
        spots: spots,
        lockToken: lockToken,
        sendConfirmationEmail: false,
        sendReminderEmail: false
    };

    return state;
};
