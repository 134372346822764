import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Modal, Row } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BaseForm from '../components/base-form';
import CalendarDates from '../components/calendar-dates';
import Loading from '../components/loading';
import TopNav from '../components/top-nav';
import {
    getCalendar,
    getCalendarDates,
    createCalendarDates,
    deleteCalendarDates,
    updateCalendarDates,
    formatCalendarTitle
} from '../data/calendar';
import { MONTH_NAMES, WEEKDAYS } from '../data/utils';

const CalendarDatesRoute = () => {
    const { id } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const currentDate = new Date();
    const options = [...Array(12).keys()].map((index) => {
        let newDate = '';

        if (index === 11) {
            newDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + index + 1, 0);
        } else {
            newDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + index);
        }

        return {
            label: MONTH_NAMES[newDate.getMonth()] + ' ' + newDate.getFullYear(),
            value: newDate
        };
    });
    const defaultSettings = {
        startDate: null,
        endDate: null,
        interval: 'daily',
        weekdays: [1, 2, 3, 4, 5]
    };
    const colSizes = {
        sm: 6,
        lg: 6,
        xl: 4
    };

    const startDate = options[0].value;
    const endDate = options[11].value;
    const [mode, setMode] = useState(null);
    const [calendar, setCalendar] = useState(null);
    const [calendarDates, setCalendarDates] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [variant, setVariant] = useState('');
    const [selectedDates, setSelectedDates] = useState([]);
    const [fromDates, setFromDates] = useState([]);
    const [dateFilter, setDateFilter] = useState(defaultSettings);
    const oneYearFromNow = moment().add(1, 'Y').toDate();

    const isValidSelection = (calendarDate) => {
        return (mode === 'Add' && !calendarDates.find(x => x.date === calendarDate)) ||
            (mode === 'Edit' && fromDates.length < 1 && calendarDates.find(x => x.date === calendarDate && (x.capacity === 0 || calendar.groupSession))) ||
            (mode === 'Edit' && fromDates.length > 0 && !calendarDates.find(x => x.date === calendarDate && (x.capacity === 0 || calendar.groupSession))) ||
            (mode === 'Move' && fromDates.length < 1 && calendarDates.find(x => x.date === calendarDate)) ||
            (mode === 'Move' && fromDates.length > 0 && !calendarDates.find(x => x.date === calendarDate)) ||
            (mode === 'Copy' && fromDates.length < 1 && selectedDates.length < 1 && calendarDates.find(x => x.date === calendarDate)) ||
            (mode === 'Copy' && fromDates.length === 1 && !calendarDates.find(x => x.date === calendarDate));
    };

    const handleDateClick = (calendarDate) => {
        if (mode) {
            if (selectedDates.includes(calendarDate)) {
                setSelectedDates(selectedDates.filter(x => x !== calendarDate));
            } else {
                if (isValidSelection(calendarDate)) {
                    setSelectedDates([...selectedDates, calendarDate].sort());
                }
            }
        } else {
            const url = '/admin/calendars/' + id + '/dates/' + calendarDate + '/edit';
            navigate(url, { state: state });
        }
    };

    const renderMultiSelectForm = () => {
        const fields = [
            {
                name: 'startDate',
                type: 'date',
                label: 'Start Date',
                minDate: moment().toDate(),
                maxDate: oneYearFromNow
            },
            {
                name: 'endDate',
                type: 'date',
                label: 'End Date',
                minDate: moment().toDate(),
                maxDate: oneYearFromNow
            },
            {
                name: 'interval',
                type: 'select',
                label: 'Interval',
                options: [
                    {
                        label: 'Daily',
                        value: 'daily'
                    },
                    {
                        label: 'Weekly',
                        value: 'weekly'
                    },
                    {
                        label: 'Biweekly',
                        value: 'biweekly'
                    },
                    {
                        label: 'Monthly',
                        value: 'monthly'
                    },
                ]
            },
            {
                name: 'weekdays',
                type: 'multiselect',
                label: 'Weekdays',
                options: WEEKDAYS.map((x, index) => {
                    return {
                        label: x,
                        value: index
                    };
                })
            }
        ];

        const handleFilterChange = e => {
            let isValid = true;
            if (e.startDate && e.endDate) {
                if (e.endDate < e.startDate) {
                    isValid = false;
                }
            }

            if (isValid) {
                setDateFilter(e);
            }

            return true;
        };

        const handleEditClick = () => {
            if (mode === 'Add' || mode === 'Edit') {
                const url = '/admin/calendars/' + id + '/dates/batch/' + mode.toLowerCase();
                navigate(url, { state: selectedDates });
            } else {
                if (fromDates.length < 1) {
                    setFromDates([...selectedDates]);
                    setSelectedDates([]);
                } else {
                    setModalShow(true);
                }
            }
        };

        const renderActionLabel = () => {
            if (mode === 'Move' || mode === 'Copy') {
                if (fromDates.length > 0) {
                    return mode + ' selected date(s)';
                }

                return 'Confirm selection';
            }

            return mode + ' selected date(s)';
        };

        const canRenderActionButton = () => {
            if ((mode === 'Move') && fromDates.length > 0) {
                return selectedDates.length === fromDates.length;
            }

            return selectedDates.length > 0;
        };

        const renderFromDates = () => {
            if (fromDates.length < 1) {
                return '';
            }

            return (
                <>
                    <p>Date(s) to {mode}</p>
                    <ul>
                        {fromDates.map((x, i) => <li key={i}>{x}</li>)}
                    </ul>
                </>
            );
        };

        const customSubmitElement = (
            <Row>
                {canRenderActionButton() ? <Col xs={2}>
                    <Button variant="primary" className="w-100" onClick={handleEditClick} type="button">
                        {renderActionLabel()}
                    </Button>
                </Col> : <></>}
                {selectedDates.length > 0 && mode === 'Edit' ? <Col xs={2}>
                    <Button variant="danger" className="w-100" onClick={() => setModalShow(true)} type="button">
                        Delete Dates
                    </Button>

                </Col> : <></>}
                <Col xs={2}>
                    <Button variant="primary" className="w-100" type="button" onClick={() => setMode(null)}>
                        Cancel
                    </Button>
                </Col>
                <p className="my-4">{selectedDates.length} date(s) selected.</p>
                {renderFromDates()}
            </Row>
        );

        const renderInstructions = () => {
            let text = 'Select the dates you want to ' + mode.toLowerCase() + '.';

            if (mode === 'Add') {
                text += 'Click Add Selected Date(s) when you are done. Remember: You can only add dates that do not currently exist (no grey bar under the date). ';
            } else if (mode === 'Move') {
                if (fromDates.length > 0) {
                    if (fromDates.length !== selectedDates.length) {
                        text = 'Select the new date(s). You still have ' + (fromDates.length - selectedDates.length);
                        text += ' date(s) to select. Remember to keep the same order!';
                    } else {
                        text = 'Your new date(s) have been selected. Click Confirm Selection.';
                    }
                } else {
                    text += ' Click Confirm Selection when you are done.';
                }
            } else if (mode === 'Copy') {
                if (fromDates.length > 0) {
                    if (selectedDates.length < 1) {
                        text = 'Select the date(s) your want to copy the date to.';
                    } else {
                        text = 'Click Confirm Selection when you are done.';
                    }
                } else {
                    text = 'Select the date you want to copy, then click Confirm Selection.';
                }
            } else {
                text += ' You can only select dates that do not currently have no appointments.';
            }

            return text;
        };

        return (
            <Row className="my-2">
                <h2>{mode} Dates</h2>
                <BaseForm
                    fields={fields}
                    formState={dateFilter}
                    setFormStateCallback={x => handleFilterChange(x)}
                    customSubmitElement={customSubmitElement} />
                <Alert variant="info">
                    {renderInstructions()}
                </Alert>
            </Row>
        );
    };

    const handleError = (e) => {
        setMessage('Error: ' + e.toString());
        setVariant('danger');
        setShowAlert(true);
        setMode(null);
    };

    const handleSuccess = (message) => {
        getCalendarDates(id, startDate, endDate).then(data => {
            setCalendarDates(data.items);
            setMessage(message);
            setVariant('success');
            setShowAlert(true);
            setMode(null);
            window.scrollTo(0, 0);
            setTimeout(() => setShowAlert(false), 5000);
        }).catch(e => {
            handleError(e);
        });
    };

    const ConfirmationDialog = (props) => {
        if (!mode) {
            return <></>;
        }

        const handleConfirmAction = () => {
            if (mode === 'Edit') {
                deleteCalendarDates(id, selectedDates).then(() => {
                    handleSuccess('The date(s) have been deleted with success.');
                }).catch(e => {
                    handleError(e);
                });
            } else {
                if (mode === 'Move') {
                    const params = {
                        fromDates: fromDates,
                        toDates: selectedDates
                    };
                    updateCalendarDates(id, params).then(() => {
                        handleSuccess('The date(s) have been moved.');
                    }).catch(e => {
                        handleError(e);
                    });
                } else if (mode === 'Copy') {
                    const params = {
                        fromDate: fromDates[0],
                        toDates: selectedDates
                    };
                    createCalendarDates(id, params).then(() => {
                        handleSuccess('The date(s) have been copied.');
                    }).catch(e => {
                        handleError(e);
                    });
                }
            }
        };

        const modalBody = 'Are you sure you want to ' + mode.toLowerCase() + ' the selected date(s)?';
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Confirmation
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><b>{modalBody}</b></p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => { props.onHide(); handleConfirmAction(); }}>Confirm</Button>
                    <Button onClick={props.onHide}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        );
    };

    useEffect(() => {
        Promise.all([
            getCalendar(id, { location: true, category: true, lanes: false }),
            getCalendarDates(id, startDate, endDate)
        ]).then((res) => {
            setCalendar(res[0]);
            setCalendarDates(res[1].items);
        }).catch(e => {
            handleError(e);
        });

        if (state) {
            setMessage(state.message);
            setVariant(state.variant);
            setShowAlert(true);
            window.scrollTo(0, 0);
            setTimeout(() => setShowAlert(false), 5000);
        }
    }, []);

    useEffect(() => {
        let newSelectedDates = [];
        let x = moment(dateFilter.startDate);
        const end = moment(dateFilter.endDate);
        let skipFlag = false;

        while (x <= end) {
            const formattedDate = x.format('YYYY-MM-DD');
            if (!skipFlag && dateFilter.weekdays.includes(x.day())) {
                if (isValidSelection(formattedDate)) {
                    newSelectedDates.push(formattedDate);
                }
            }

            if (dateFilter.interval === 'biweekly' && x.day() === 6) {
                skipFlag = !skipFlag;
            }

            let period = 'days';

            if (dateFilter.interval === 'weekly') {
                period = 'weeks';
            }

            if (dateFilter.interval === 'monthly') {
                period = 'months';
            }

            x = x.add(1, period);
        }

        setSelectedDates(newSelectedDates);
    }, [dateFilter]);

    useEffect(() => {
        if (!mode) {
            setDateFilter(defaultSettings);
            setSelectedDates([]);
            setFromDates([]);
        }
    }, [mode]);

    useEffect(() => {
        if (!modalShow) {
            setMode(null);
        }
    }, [modalShow]);

    if (!calendar) {
        return <Loading />;
    }

    const previousItems = [
        {
            label: 'Calendars',
            link: '/admin/calendars'
        }
    ];

    const getModes = () => {
        let modes = ['Add', 'Edit', 'Move', 'Copy'];
        return modes;
    };

    const title = formatCalendarTitle(calendar);

    return (
        <>
            <TopNav activeItem={title} previousItems={previousItems} />
            <h1>{title}</h1>
            <Alert show={showAlert} variant={variant}>{message}</Alert>
            <div className="my-3">{!mode ? getModes().map((x, i) => <Button className="me-2" key={i} onClick={() => setMode(x)} variant="primary">{x + ' Dates'}</Button>) : ''}</div>
            {mode ? renderMultiSelectForm() : <></>}
            <CalendarDates
                startDate={startDate}
                endDate={endDate}
                calendarDates={calendarDates}
                isSidebar={false}
                dateSelectedCallback={handleDateClick}
                selectedDates={selectedDates}
                colSizes={colSizes}
                shortWeekDays={true}
                multiSelectMode={mode !== null} />
            <ConfirmationDialog show={modalShow} onHide={() => setModalShow(false)} />
        </>
    );
};

export default CalendarDatesRoute;
