import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Alert, Button, Form, Table } from 'react-bootstrap';
import BaseForm from '../components/base-form';
import CalendarLanes from '../components/calendar-lanes';
import { getCalendar, createCalendarDates, editCalendarDates } from '../data/calendar';
import Loading from '../components/loading';
import { formatDate } from '../data/utils';
import TopNav from '../components/top-nav';

const CalendarDatesBatchRoute = ({ mode = "Add" }) => {
    const { id } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();
    const [calendar, setCalendar] = useState(null);
    const [batchData, setBatchData] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [variant, setVariant] = useState('');
    const [showDates, setShowDates] = useState(false);
    const previousItems = [
        {
            label: 'Calendars',
            link: '/admin/calendars'
        },
        {
            label: 'Calendar Dates',
            link: '/admin/calendars/' + id + '/dates'
        }
    ];

    const handleSubmit = () => {
        const response = mode === 'Add' ? createCalendarDates(id, batchData) : editCalendarDates(id, batchData);
        response.then(() => {
            const state = {
                message: 'The calendar date(s) have been ' + (mode === 'Add' ? 'created.' : 'modified.'),
                variant: 'success'
            };
            navigate('/admin/calendars/' + id + '/dates', { state: state });
        }).catch(e => {
            setMessage(e.toString());
            setVariant('danger');
            setShowAlert(true);
        });
    };

    const handleStateChange = (newState) => {
        setBatchData(newState);
    };

    useEffect(() => {
        const params = {
            lanes: true
        };
        getCalendar(id, params)
            .then(response => {
                setCalendar(response);
                setBatchData({
                    deadline: state[0],
                    spots: response.spots,
                    dates: state,
                    lanes: response.lanes
                });
            }).catch(e => {
                setMessage(e.toString());
                setVariant('danger');
                setShowAlert(true);
            });
    }, []);

    const renderDateTable = () => {
        return (
            <Table striped bordered hover >
                <thead>
                    <tr>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {state.map(x =>
                        <tr>
                            <td>{formatDate(x)}</td>
                        </tr>
                    )}
                </tbody>
            </Table >
        );
    };

    if (!calendar || !batchData) {
        return <Loading />;
    }

    let fields = [{
        name: 'lanes',
        label: '',
        placeholder: '',
        type: 'custom',
        required: true,
        element: <CalendarLanes
            isGroupSession={calendar.groupSession}
            calendarLanes={batchData.lanes}
            handleStateChange={(data) => handleStateChange({ ...batchData, lanes: data })}
        />
    }];

    if (calendar.groupSession) {
        fields = [
        {
            name: 'deadline',
            label: 'Registration Deadline',
            placeholder: '',
            type: 'date',
            required: true
        },
        {
            name: 'spots',
            label: 'Spots',
            placeholder: '',
            type: 'number',
            min: 1,
            max: 100,
            required: true
        },
        ...fields];
    }

    const title = mode + ' ' + (calendar.groupSession ? 'Appointment Series' : 'Calendar Dates') + ' - ' + calendar.name;

    return (
        <>
          <TopNav activeItem={title} previousItems={previousItems} />
          <h1>{title}</h1>
            <Alert show={showAlert} variant={variant}>{message}</Alert>
            <p><strong>{state.length}</strong> date(s) to {mode.toLowerCase()}.</p>
            <Button className="mb-3" variant="primary" type="button" onClick={() => setShowDates(!showDates)}>
                {showDates ? 'Hide' : 'Show'} Date(s)
            </Button>
            {showDates ? renderDateTable() : <></>}
            <BaseForm
                fields={fields}
                formState={batchData}
                setFormStateCallback={handleStateChange}
                submitButtonLabel="Save Calendar Date(s)"
                validFormCallback={handleSubmit}
            />
        </>
    );
};

export default CalendarDatesBatchRoute;
